import { useState, useRef, useEffect } from 'react';
import './App.css';
import { useCallback } from 'react';

const Hotspot = ({x, y, left, top, size} = {}) => {
  return (
    <div className='hotspot' style={{
      left: `${x}%`,
      top: `${y}%`,
      // left: `${left}%`,
      // top: `${top}%`,
      width: size,
      height: size
    }}>
      
    </div>
  )
}

function App() {
  const [hotspots, setHotspots] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [layout, setLayout] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0
  });
  const imgRef = useRef();
  const size = 30;

  const onAddHotspot = useCallback((event) => {
    const {pageX, pageY, target} = event;
    const rect = target.getBoundingClientRect();
    const {left, top} = rect;
    const x = (pageX - left) - (size / 2);
    const y = (pageY - top) - (size / 2);
    
    const height = layout.height;
    setHotspots([...hotspots, {
      x: ((x / layout.width) * 100).toFixed(2),
      y: ((y / height) * 100).toFixed(2),
      productId: ""
    }]);
  }, [layout.width, layout.height, hotspots]);

  const handleSelectImage = useCallback((event) => {
    const {target} = event;
    const [file] = target?.files || [];
    setSelectedImage(URL.createObjectURL(file));
  }, []);

  useEffect(() => {
    if (selectedImage && imgRef.current && (layout.width === 0 || layout.height === 0)) {
      setLayout({
        width: imgRef.current.clientWidth,
        height: imgRef.current.clientHeight,
        left: 0,
        top: 0
      });
    }
  }, [imgRef, layout, selectedImage]);
  
  return (
    <>
     {!selectedImage && (
      <div className='container file-input-control'>
        <input type='file' onChange={handleSelectImage} value={""} />
      </div>
     )}
      {selectedImage && (
        <>
          <div className='container' >
            <div className='img-wrapper'>
              <img ref={imgRef} alt='img' src={selectedImage} onClick={onAddHotspot} />
              {hotspots.map((item, key) => <Hotspot key={`item-${key}`} {...item} size={size} />)}
              <div className='y-axis'></div>
              <div className='x-axis'></div>
            </div>
            <p>w: {layout.width} / h: {layout.height}</p>
          </div>
          <div className='text-wrapper'>
            <textarea className='hotspots-text' cols={20} value={JSON.stringify(hotspots, null, 4)} />
          </div>
        </>
      )}
    </>
  );
}

export default App;
